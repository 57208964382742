import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/packages/recipes/src/layouts/recipe.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h5>{`Prep time: 45 min. `}<meta itemProp="prepTime" content="PT45M" /></h5>
    <h5>{`Cook time: 90 min. `}<meta itemProp="cookTime" content="PT90M" /></h5>
    <h5>{`Servings: `}<span itemProp="recipeYield">{`8`}</span></h5>
    <hr></hr>
    <h2>{`Ingredients`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="recipeIngredient">
  Flour, 2 cups plus extra to flour work surface
        </span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">
  Sugar, 2 tbs for savory or 1/2 cup for sweet
        </span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Salt, 1/2 tsp.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Baking powder, 1 tbs.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Butter, 1/2 cup grated</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Buttermilk, 1/2 cup</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Egg, 1 large</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Mix ins, 1-1 1/2 cups</span>
      </li>
    </ul>
    <h3>{`For sweet lemon scones`}</h3>
    <ul>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Vanilla extract, 1 1/2 tsp.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Lemon, juice from half lemon</span>
      </li>
    </ul>
    <h3>{`For savory cheese and jalapeno scones`}</h3>
    <ul>
      <li parentName="ul">
        <span itemProp="recipeIngredient">
  Cheese, 1 cup shredded (Cabot New York Extra Sharp Cheddar)
        </span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Jalapeno peppers, 1/4 cup chopped</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Equipment`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="tool">Large mixing bowl</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Small mixing bowl</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Sifter</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Measuring cups + spoons</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Grater</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Plastic wrap</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Cookie sheet</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Parchment paper</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Prep`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="step">Grate frozen butter</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Grate cheese, for savory</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Chop jalapeno peppers, for savory</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Instructions`}</h2>
    <span itemProp="recipeInstructions">
      <ol>
        <li parentName="ol">
          <p parentName="li">{`Sift dry ingredients into large mixing bowl and mix: flour, sugar, salt, and baking powder.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Mix wet ingredients in small mixing bowl: buttermilk and egg.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Mix in grated frozen butter to dry ingredients.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Mix in wet ingredients.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Add mix ins: vanilla and lemon juice for sweet, cheese and jalapeno for savory.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Mix until well combined.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Flour work surface and shape dough into wedges.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Wrap with plastic and refrigerate for at least 15 minutes.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Line baking sheet with parchment paper.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Bake at 400F for 18-26 minutes.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Cool before serving.`}</p>
        </li>
      </ol>
    </span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      